import React, { Component } from "react";
import dayjs from "dayjs";
import CountUp from "react-countup";
import "./App.css";

const getDiff = (): number => dayjs().diff(dayjs(1399343700000), "year", true);

const initialState = {
  t1: getDiff() - 0.0000003,
  t2: getDiff()
};
type State = Readonly<typeof initialState>;

class App extends Component<{}, State> {
  readonly state: State = initialState;
  inc() {
    this.setState({
      t2: getDiff(),
      t1: this.state.t2
    });
  }
  componentDidMount() {
    setInterval(() => this.inc(), 10000);
  }
  render() {
    const { t1, t2 } = this.state;
    return (
      <div className="App">
        <header className="App-header">
          <span className="header-footer">Now that we are at</span>
          <CountUp
            start={t1}
            end={t2}
            delay={0}
            decimals={9}
            useEasing={false}
            duration={10}
          >
            {({ countUpRef }) => (
              <div>
                <span ref={countUpRef} />
              </div>
            )}
          </CountUp>
        </header>
      </div>
    );
  }
}

export default App;
